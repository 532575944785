<template>
  <div class="pay-page">

    <div class="text-center mt-3 fw-bold">
      <p>
        Payment process in progress...
        <br />
        Please don't take any action. Your payment is processed, it may take a few minutes.
      </p>
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

  </div>
</template>

<script>
import {PayStatusEnum} from '@/modules/pay/enums/PayEnum';

export default {

  data: () => ({
    payData: null,
    timerObject: null
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.setTimer();
  },

  methods: {

    async doPayProcess() {

      let apiHolder = await this.sendApiGet(['api/pay/process/' + this.$route.params?.id, {
        extPayToken: this.$route.query?.Token
      }], {
        isDisableLoad: true
      });

      if (!apiHolder.isSuccess())
          return;

      if (apiHolder.data?.payData)
        this.payData = apiHolder.data.payData;

      if (this.payData?.status == PayStatusEnum.success)
        await this.redirect('/order/view/' + this.payData.orderId, 'Payment was successful', 'success');
    },

    async setTimer() {

      this.timerObject = setInterval(async () => {

        await this.doPayProcess();
      }, 3000);
    }
  },

  beforeUnmount() {

    if (this.timerObject)
      clearInterval(this.timerObject);
  }
}
</script>

<style scoped>

</style>