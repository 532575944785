
const PayStatusEnum = {

	new: 'new',
	process: 'process',
	success: 'success',
	error: 'error',
	cancel: 'cancel',
	delete: 'delete',

	getLabels: function() {

		return {
			new: 'new',
			process: 'process',
			success: 'success',
			error: 'error',
			cancel: 'cancel',
		}
	}

};

module.exports = {PayStatusEnum};